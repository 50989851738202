<template>
  <div>
    <h4>Types de recherche</h4>
    
    <data-table
      :data-source="sortedTypeRecherches"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-type-analyse"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
        
    <modal
      title="Ajout du type de recherche"
      id="add-type-analyse"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>

        <div class="form-group">
          <label for="add-type">Type</label>
          <select
            id="add-type"
            name="add-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typesCanHaveCategories"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-categorie">Categorie</label>
          <select
            id="add-categorie"
            name="add-categorie"
            class="form-control"
            v-model="categorie"
            v-select="{placeholder: 'Selectionnez la catgorie d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(c, i) in filtredCategories"
              :value="c.uid"
              :key="i"
            >
              {{ c.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hasExamen"
                  id="add-has-examen"
                >
                <label
                  class="form-check-label"
                  for="add-has-examen"
                >
                  Comporte des examens?
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hasFiche"
                  id="add-has-fiche"
                >
                <label
                  class="form-check-label"
                  for="add-has-fiche"
                >
                  Existe fiche d'analyse?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          v-if="hasFiche"
        >
          <div class="col-6">
            <div class="form-group">
              <label for="add-type-fiche">Type de fiche</label>
              <select
                class="form-control"
                v-model="typeFiche"
                v-select="{placeholder: 'Selectionnez le type de fiche'}"
                name="add-type-fiche"
                id="add-type-fiche"
              >
                <option value="" />
                <option
                  v-for="(t, i) in typeFiches"
                  :value="t.uid"
                  :key="i"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="add-fiche">Fiche</label>
              <select
                class="form-control"
                v-model="fiche"
                v-select="{placeholder: 'Selectionnez la fiche'}"
                name="add-fiche"
                id="add-fiche"
              >
                <option value="" />
                <option
                  v-for="(f, i) in filtredFicheAnalyses"
                  :value="f.uid"
                  :key="i"
                >
                  {{ f.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>

    <modal
      title="Modification du type de recherche"
      id="update-type-analyse"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editting"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>

        <div class="form-group">
          <label for="update-type">Type</label>
          <select
            id="update-type"
            name="update-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typesCanHaveCategories"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-categorie">Categorie</label>
          <select
            id="update-categorie"
            name="update-categorie"
            class="form-control"
            v-model="categorie"
            v-select="{placeholder: 'Selectionnez la catgorie d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(c, i) in filtredCategories"
              :value="c.uid"
              :key="i"
            >
              {{ c.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hasExamen"
                  id="update-has-examen"
                >
                <label
                  class="form-check-label"
                  for="update-has-examen"
                >
                  Comporte des examens?
                </label>
              </div>
            </div>    
          </div>
          <div class="col-6">
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hasFiche"
                  id="update-has-fiche"
                >
                <label
                  class="form-check-label"
                  for="update-has-fiche"
                >
                  Existe fiche d'analyse?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          v-if="hasFiche"
        >
          <div class="col-6">
            <div class="form-group">
              <label for="update-type-fiche">Type de fiche</label>
              <select
                class="form-control"
                v-model="typeFiche"
                v-select="{placeholder: 'Selectionnez le type de fiche'}"
                name="update-type-fiche"
                id="update-type-fiche"
              >
                <option value="" />
                <option
                  v-for="(t, i) in typeFiches"
                  :value="t.uid"
                  :key="i"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="update-fiche">Fiche</label>
              <select
                class="form-control"
                v-model="fiche"
                v-select="{placeholder: 'Selectionnez la fiche'}"
                name="update-fiche"
                id="update-fiche"
              >
                <option value="" />
                <option
                  v-for="(f, i) in filtredFicheAnalyses"
                  :value="f.uid"
                  :key="i"
                >
                  {{ f.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        

        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import { BOOLEAN_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import { ADD_TYPE_RECHERCHE, UPDATE_TYPE_RECHERCHE } from '../../../../graphql/sanitaire'
const typeAnalyse = () => import('../../../../components/admin/sanitaire/typeRecherche/typeAnalyse.vue')
const categorieAnalyse = () => import('../../../../components/admin/sanitaire/typeRecherche/categorieAnalyse.vue')
const Actions = () => import('../../../../components/admin/sanitaire/typeRecherche/actions.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            categorie: null,
            filtredCategories: [],
            type: null,
            hasExamen: false,
            typeFiche: null,
            filtredFicheAnalyses: [],
            hasFiche: false,
            fiche: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        type(){
            if(this.type !== null){
                this.filtredCategories = [...this.categories].filter(item => item.typeAnalyse === this.type && item.hasRecherche)
            } else this.filtredCategories =  []
        },
        typeFiche(){
          this.filtredFicheAnalyses = []
          if(this.typeFiche !== null) this.filtredFicheAnalyses = [...this.ficheAnalyses].filter(item => item.categorieAnalyse === this.typeFiche)
        },
        selectedType: {
            handler(){
                if(this.selectedType !== null) {
                    this.libelle = this.selectedType.libelle
                    this.hasExamen = this.selectedType.hasExamen
                    this.categorie = this.selectedType.categorieAnalyse
                    let c = this.categories.find(item => item.uid === this.categorie)
                    let t = this.types.find(item => item.uid === c.typeAnalyse)
                    this.type = t.uid
                    this.hasFiche = this.selectedType.hasFiche
                    this.fiche = this.selectedType.fiche
                    if(this.selectedType.hasFiche){
                      let f = this.ficheAnalyses.find(item => item.uid === this.selectedType.fiche)
                      let tf = this.typeFiches.find(item => item.uid === f.categorieAnalyse)
                      if(tf) this.typeFiche = tf.uid
                      else this.typeFiche = null
                    }else{
                      this.typeFiche = null
                    }
                     
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.hasExamen = false
            this.hasFiche = false
            this.fiche = null
            this.type = null
            this.categorie =  null
            this.has_error = false
            this.error_msg = null
        },
        adding(){
            let data = {
                libelle: this.libelle,
                hasExamen : this.hasExamen,
                categorieAnalyse: this.categorie,
                hasFiche: this.hasFiche,
                fiche: this.fiche
            }
            this.$apollo.mutate({
                mutation: ADD_TYPE_RECHERCHE,
                variables: {
                    "type": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Type de recherche ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editting(){
            let data = {
                libelle: this.libelle,
                hasExamen : this.hasExamen,
                categorieAnalyse: this.categorie,
                hasFiche: this.hasFiche,
                fiche: this.fiche
            }
            this.$apollo.mutate({
                mutation: UPDATE_TYPE_RECHERCHE,
                variables: {
                    "type": {
                        ...data
                    },
                    "uid": this.selectedType.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Type de recherche ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            selectedType: 'selectedObject',
            typeRecherches: 'sanitaire/typeRecherches',
            typeFiches: 'sanitaire/typeFicheAnalyses',
            ficheAnalyses: 'sanitaire/ficheAnalyses'
        }),
        sortedTypeRecherches(){
          return [...this.typeRecherches].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        typesCanHaveCategories(){
            return [...this.types.filter(item => item.hasCategorie)]
        },
        categoriesCanHaveTypeRecherches(){
            return [...this.categories.filter(item => item.hasRecherche)]
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Type Analyse', name: 'type_analyse', type: COMPONENT_TYPE, component: typeAnalyse},
                {label: 'Categorie Analyse', name: 'categorie_analyse', type: COMPONENT_TYPE, component: categorieAnalyse},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE, sortable: true},
                {label: 'Has Examen', name: 'hasExamen', type: BOOLEAN_TYPE},
                {label: 'Has Fiche', name: 'hasFiche', type: BOOLEAN_TYPE},
                {label: 'Fiche', name: 'fiche', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }
}
</script>

<style>

</style>